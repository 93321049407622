<template>
  <div>
    <div class="about_bg">
      <div class="container guanyu">
        <div class="title">关于酒搭
        </div>
        <div class="des">
          &emsp; &emsp; 成都酒搭网络科技有限公司（简称酒搭）成立于2016年，是新商业软件时代极具影响力的营销数字推广SaaS服务商。致力于帮助企业建立数字化营销推广中台，为品牌企业、二类电商等电商客户提供整合营销数字化解决方案，提高营销效益。为整合营销效果而生！
          <br> &emsp; &emsp; 酒搭旗下有两款产品：1.面向二类电商用户的营销推广数字系统——“羽林二类电商云 ”。2.面向品牌客户的营销数字化解决方案“羽林品牌企业推广云 ”系统。帮助企业通过一个数字营销推广系统打通服务商与物流、信息流及终端消费者的营销推广数字化业务链接，通过用户行为分析，为效果付费，提升数字战斗力。
          <br> &emsp; &emsp; 公司已获得天使轮融资，是营销推广领域最有潜力的SaaS软件独角兽企业，并且与企业微信、华为云、金蝶、顺丰、中国移动等建立生态合作伙伴。公司管理团队成员来自金蝶、百度、移动、腾讯等知名企业，在电信业务、ERP和云计算领域具有20年企业软件的实践，现服务企业用户超过几千家，是营销推广数字化领域极具影响力的新商业软件公司。

        </div>
      </div>
    </div>
    <div class="container">
      <div class="about_us_list flex align-center">
        <div>
          <img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/f2bcae34387702299481313651/f0.png" alt="">
          <div>
            <h2>愿景</h2>
            <p>云时代营销数字化服务领导者</p>
          </div>
        </div>
        <div>
          <img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/4268aa3f387702299482467147/f0.png" alt="">
          <div>
            <h2>使命</h2>
            <p>创新营销软件 成就客户价值</p>
          </div>
        </div>
        <div>
          <img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/f2bc9873387702299481313119/f0.png" alt="">
          <div>
            <h2>公司定位</h2>
            <p>为效果付费的营销推广SaaS服务商
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.about_bg {
  width: 1200px;
  margin: 0 auto;
  background: linear-gradient(150deg, #317bff, #1740df);
  box-shadow: 0px 1px 8px 0px rgba(0, 48, 101, 0.2);
  margin-bottom: 100px;
  padding: 52px 40px 52px;
  .title {
    font-size: 36px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-bottom: 58px;
  }

  .des {
    font-size: 16px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
  }
}

.about_us_list {
  justify-content: space-between;
  margin-bottom: 90px;
  & > div {
    font-size: 0;
    img {
      width: 280px;
    }

    div {
      height: 65px;
      background: #1858c3;
      padding: 15px 0;
      h2 {
        font-size: 26px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 36px;
      }
      > p {
        font-size: 18px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
      }
    }
  }
}

@media all and (max-width: 1080px) {
  .about_bg {
    padding: 30px 20px 20px;
    width: auto;
    .title {
      font-size: 24px;
    }
    .des {
      width: auto;
      font-size: 16px;
    }
  }

  .about_us_list {
    padding: 0 10px;
    & > div {
      margin-bottom: 10px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
